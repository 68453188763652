<template>
	<div class="deteleOrganBox">
		<!-- <div class="cq_back_text">
			<span @click='$router.back()'>返回</span><a-divider type="vertical" /><span>工作地点</span><a-divider
				type="vertical" /><span class="back_text">调整排序</span>
		</div> -->
		<div class="del_o_b_contain">
			<div class="del_b_b_c_tips">
				<p>使用指南:</p>
				<p>鼠标移至部门上方，可对部门进行置顶、上移或下移。</p>
			</div>

			<div class="del_o_b_contain_box">
				<a-tree :replaceFields="{children:'children', title:'name', key:'id' }" :tree-data="treeData"
					:defaultExpandAll="true">
					<template #name="{name,pid,id,index,length }">
						<div class="sortRecord_box">
							<div class="sortRecord_box_text">{{name}}</div>
							<div class="sortRecord_box_btns">
								<a-button class='pointerCancel' v-if='index!=0' size="small"
									@click="upward({pid:pid,id:id},3)">置顶</a-button>
								<a-button class='pointerCancel' size="small" v-if='index!=0'
									@click="upward({pid:pid,id:id},1)">向上</a-button>
								<a-button class='pointerCancel' size="small" v-if='index!=length'
									@click="upward({pid:pid,id:id},2)">向下</a-button>
								<a-button class='pointerCancel' size="small" v-if='index!=length'
									@click="upward({pid:pid,id:id},4)">置底</a-button>
							</div>
						</div>
					</template>
				</a-tree>
			</div>
		</div>
		<a-drawer placement="bottom" :closable="false" height="auto" :mask='false' :visible="visible">
			<div style="text-align: center;">
				<a-button class='pointerCancel' @click="delClose">取消</a-button>
				<a-button class='pointerCancel' style="margin-left: 20px;" type='primary'
					@click="delSuccess">确认排序</a-button>
			</div>

		</a-drawer>
		<!-- <div class="del_o_b_footer">
			<div class="del_o_b_footer_btns">
				
			</div>
		</div> -->
	</div>
</template>

<script>
	import {
		message
	} from 'ant-design-vue';
	import {
		getWorkLocationTree,
		workLocationSort
	} from '@/api/organization.js'

	export default {
		data() {
			return {
				visible: true,
				treeData: [],
				selectArr: [], //选中的值
			}
		},
		created() {
			getWorkLocationTree().then(res => {
				this.setTreeItem(res.data.list)
				this.treeData = res.data.list
			})
		},
		methods: {
			setTreeItem(data) {
				data.map((item, index) => {
					item.scopedSlots = {
						title: 'name'
					}
					item.index = index
					item.length = data.length - 1
					if (item.children) {
						this.setTreeItem(item.children);
					}
				});
			},
			treeDelcheck(e) {
				this.selectArr = e
			},
			//取消
			delClose() {
				this.$router.back()
			},
			//删除
			delSuccess() {
				workLocationSort({
					data: {
						data: this.treeData
					},
					toast: true
				}).then(res => {
					this.$router.back()
				})
			},
			foreachTree(item) {
				let tempid = item.pid
				let temid = item.id

			},
			arrIndexExchange(array, x, y) {
				array.splice(x, 1, ...array.splice(y, 1, array[x]))
				return array
			},
			up(array, index) {
				if (index === 0) return
				// 将上一个数组元素值替换为当前元素值，并将被替换的元素值赋值给当前元素
				array[index] = array.splice(index - 1, 1, array[index])[0]

			},
			down(array, index) {
				if (index === array.length - 1) return
				// 将下个数组元素值替换为当前元素值，并将被替换的元素值赋值给当前元素
				let obj1 = array[index]
				let obj2 = array[index + 1]
				array[index] = JSON.parse(JSON.stringify(obj2))
				array[index + 1] = JSON.parse(JSON.stringify(obj1))
				return array
			},
			top(array, index) {
				if (index === 0) return
				// 删除当前数组元素，并将被删除的值添加到数组开头
				array.unshift(array.splice(index, 1)[0])

			},
			bottom(array, index) {
				if (index === array.length - 1) return
				// 删除当前数组元素，并将被删除的值添加到数组末尾
				array.push(array.splice(index, 1)[0])

			},
			getTreeItem(data, id, type) {
				for(let index=0;index<data.length;index++){
					let item = data[index]
					if (item.id == id) {
						this.set_sort(data, index, type)
						this.setTreeItem(this.treeData)
						return false
					} else {
						if (item.children) {
							this.getTreeItem(item.children, id, type);
						}
					}
				}
			
			},
			set_sort(arr, index, type) {
				if (type == 1) {
					this.up(arr, index)
				}
				if (type == 2) {
					this.down(arr, index)
				}
				if (type == 3) {
					this.top(arr, index)
				}
				if (type == 4) {
					this.bottom(arr, index)
				}
			},
			upward(record, type) {
				this.getTreeItem(this.treeData, record.id, type)
			}
		}
	}
</script>
<style lang="less" scoped="scoped">
	@import url("../../../assets/less/app.less");

	.deteleOrganBox {
		// margin: 12px;
		padding: 20px;
		background-color: #fff;
		min-height: 100vh;

		.del_o_b_header {
			display: flex;
			align-items: center;

			div {
				margin: 0 10px;
				padding: 0 10px;
				position: relative;
				cursor: pointer;
			}

			span {
				color: #dddee1;
				font-size: 14px;
			}

			.del_o_b_h_fanhui:hover {
				color: #397dee;
			}

			.del_o_b_h_index:hover {
				color: #397dee;
			}
		}

		.del_o_b_contain {
			margin: 16px auto;
			margin-top: 0px;
			padding-bottom: 70px;

			.del_b_b_c_tips {
				font-size: 14px;
			}

			.del_o_b_contain_box {
				margin: 20px 0;
				padding: 20px;
				border: 1px solid #e1e6eb;
				overflow: hidden;

				.sortRecord_box {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;

					// height: 50px;
					.sortRecord_box_text {
						max-width: 200px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}

					.sortRecord_box_btns {
						margin-right: 10px;
						display: flex;
						align-items: center;
						display: none;

						.ant-btn {
							margin: 0 10px;
						}

						.ant-btn:last-child {
							margin-right: 0;
						}
					}
				}

				.sortRecord_box:hover .sortRecord_box_btns {
					display: flex;
				}
			}
		}

		.del_o_b_footer {
			width: calc(100vw - 120px);
			background-color: #98b8eb;
			height: 0;
			position: fixed;
			bottom: 0;
			left: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: .3s all;
			overflow: hidden;

			.del_o_b_footer_left {
				position: absolute;
				left: 20px;
				top: 50%;
				transform: translateY(-50%);
				color: #fff;

				span {
					color: #397dee;
				}
			}

			.del_o_b_footer_btns {
				.ant-btn {
					margin: 0 10px;
				}
			}
		}

		.del_o_b_footer_active {
			height: 70px;
			display: flex;
		}
	}

	/deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
		background-color: #fff;
	}

	/deep/.ant-tree li .ant-tree-node-content-wrapper:hover {
		// background-color: ;

	}

	/deep/.ant-tree-node-content-wrapper {
		width: 100%;
		padding: 10px !important;
		height: 100% !important;
		border-bottom: 1px solid #e1e6eb
	}

	/deep/ .ant-tree-switcher {
		margin-top: 10px !important;
	}

	.cq_back_text {
		padding-left: 0;
	}

	.pointerCancel {
		padding: 0 12px;
	}
</style>